export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Aufträge',
    route: 'auftraege',
    icon: 'FolderIcon',
  },
  {
    title: 'Kunden',
    route: 'kunden',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Mitarbeiter',
    route: 'mitarbeiter',
    icon: 'UsersIcon',
  },
];
